<template>
    <section class="section__body">

        <!--- Navigation --->
        <TracksList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">

            <div class="horizontal-line mb__12">
                <div class="field-group mr__12">
                    <label class="field-group__label" for="tracks-until">{{ $t('Track.Unit') }}</label>
                    <input class="field-group__input" type="text" id="tracks-until" name="tracks-until" value="Dry_530447">
                    <span class="field-group__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="input__select"/></span> </span>
                </div>
                <div class="field-group">
                    <label class="field-group__label" for="date-until">{{ $t('Track.Day') }}</label>
                    <input class="field-group__input" type="text" id="date-until" name="date-until" value="Jul 10, 2020">
                    <span class="field-group__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="input__date" /></span> </span>
                </div>
            </div>

            <section class="track-chronology plr__16 pt__16 mb__12">
                <div class="horizontal-line mb__12">
                    <button class="button button_view_base button_theme_primary button_width_100p button_size_sm mr__8">
                        {{ $t('buttons.Play') }}
                    </button>
                    <button class="button button_view_base button_theme_neutral button_width_100p button_size_sm mr__8">
                        {{ $t('buttons.Speed') }}
                    </button>
                    <button class="button button_view_base button_theme_neutral button_width_100p button_size_sm">
                        {{ $t('buttons.Points') }}
                    </button>
                </div>
                <ul class="track-chronology__list">
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_secondary_fill"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__a-point"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__movement"/></span> </span>
                            <ul class="parameter__list">
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Mileage') }}</h5>
                                    <p class="parameter__value">
                                        1.028 km
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Duration') }}</h5>
                                    <p class="parameter__value">
                                        00:04:20
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Max speed') }}</h5>
                                    <p class="parameter__value">
                                        44 km/h
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Aver speed') }}</h5>
                                    <p class="parameter__value">
                                        16 km/h
                                    </p>
                                </li>
                            </ul>

                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__low-battery"/></span> </span>
                            <h5 class="parameter__label">Low battery (15%)</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__movement"/></span> </span>
                            <ul class="parameter__list">
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Mileage') }}</h5>
                                    <p class="parameter__value">
                                        1.028 km
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Duration') }}</h5>
                                    <p class="parameter__value">
                                        00:04:20
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Max speed') }}</h5>
                                    <p class="parameter__value">
                                        44 km/h
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Aver speed') }}</h5>
                                    <p class="parameter__value">
                                        16 km/h
                                    </p>
                                </li>
                            </ul>

                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__max-speed"/></span> </span>
                            <h5 class="parameter__label">Over speed (125 km/h)</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__lost-connection"/></span> </span>
                            <h5 class="parameter__label">Loss of signal (5 min)</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_primary_fill"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__b-point"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                </ul>

                <table class="table track-chronology__table table_size_sm table_type-2-columns">
                    <tr>
                        <th>{{ $t('text.Mileage') }} :</th>
                        <td>0</td>
                    </tr>
                    <tr>
                        <th>{{ $t('text.Duration') }} :</th>
                        <td>97</td>
                    </tr>
                    <tr>
                        <th>{{ $t('text.Maximum speed') }} :</th>
                        <td>1</td>
                    </tr>
                    <tr>
                        <th>{{ $t('text.Average speed') }} :</th>
                        <td>On</td>
                    </tr>
                </table>
            </section>
            <section class="track-chronology plr__16 pt__16 mb__12">
                <div class="horizontal-line mb__12">
                    <button class="button button_view_base button_theme_primary button_width_100p button_size_sm mr__8"> Play </button>
                    <button class="button button_view_base button_theme_neutral button_width_100p button_size_sm mr__8"> Speed </button>
                    <button class="button button_view_base button_theme_neutral button_width_100p button_size_sm"> Points </button>
                </div>
                <ul class="track-chronology__list">
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_secondary_fill"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__a-point"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__movement"/></span> </span>
                            <ul class="parameter__list">
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Mileage') }}</h5>
                                    <p class="parameter__value">
                                        1.028 km
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Duration') }}</h5>
                                    <p class="parameter__value">
                                        00:04:20
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Max speed') }}</h5>
                                    <p class="parameter__value">
                                        44 km/h
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Aver speed') }}</h5>
                                    <p class="parameter__value">
                                        16 km/h
                                    </p>
                                </li>
                            </ul>

                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__movement"/></span> </span>
                            <ul class="parameter__list">
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Mileage') }}</h5>
                                    <p class="parameter__value">
                                        1.028 km
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Duration') }}</h5>
                                    <p class="parameter__value">
                                        00:04:20
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Max speed') }}</h5>
                                    <p class="parameter__value">
                                        44 km/h
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Aver speed') }}</h5>
                                    <p class="parameter__value">
                                        16 km/h
                                    </p>
                                </li>
                            </ul>

                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_primary_fill"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__b-point"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                </ul>

                <table class="table track-chronology__table table_size_sm table_type-2-columns">
                    <tr>
                        <th>{{ $t('text.Mileage') }} :</th>
                        <td>0</td>
                    </tr>
                    <tr>
                        <th>{{ $t('text.Duration') }} :</th>
                        <td>97</td>
                    </tr>
                    <tr>
                        <th>{{ $t('text.Maximum speed') }} :</th>
                        <td>1</td>
                    </tr>
                    <tr>
                        <th>{{ $t('text.Average speed') }} :</th>
                        <td>On</td>
                    </tr>
                </table>
            </section>
            <section class="track-chronology plr__16 pt__16">
                <div class="horizontal-line mb__12">
                    <button class="button button_view_base button_theme_primary button_width_100p button_size_sm mr__8"> Play </button>
                    <button class="button button_view_base button_theme_neutral button_width_100p button_size_sm mr__8"> Speed </button>
                    <button class="button button_view_base button_theme_neutral button_width_100p button_size_sm"> Points </button>
                </div>
                <ul class="track-chronology__list">
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_secondary_fill"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__a-point"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__movement"/></span> </span>
                            <ul class="parameter__list">
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Mileage') }}</h5>
                                    <p class="parameter__value">
                                        1.028 km
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Duration') }}</h5>
                                    <p class="parameter__value">
                                        00:04:20
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Max speed') }}</h5>
                                    <p class="parameter__value">
                                        44 km/h
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Aver speed') }}</h5>
                                    <p class="parameter__value">
                                        16 km/h
                                    </p>
                                </li>
                            </ul>

                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__movement"/></span> </span>
                            <ul class="parameter__list">
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Mileage') }}</h5>
                                    <p class="parameter__value">
                                        1.028 km
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Duration') }}</h5>
                                    <p class="parameter__value">
                                        00:04:20
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Max speed') }}</h5>
                                    <p class="parameter__value">
                                        44 km/h
                                    </p>
                                </li>
                                <li class="parameter__item">
                                    <h5 class="parameter__label">{{ $t('text.Aver speed') }}</h5>
                                    <p class="parameter__value">
                                        16 km/h
                                    </p>
                                </li>
                            </ul>

                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                    <li class="track-chronology__item">
                        <div class="parameter mb__12">
                            <span class="parameter__icon icon icon_type_circle icon_theme_primary_fill"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__b-point"/></span> </span>
                            <h5 class="parameter__label">12:00:00 AM</h5>
                            <p class="parameter__value">
                                5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                            </p>
                        </div>
                    </li>
                </ul>

                <table class="table track-chronology__table table_size_sm table_type-2-columns">
                    <tr>
                        <th>{{ $t('text.Mileage') }} :</th>
                        <td>0</td>
                    </tr>
                    <tr>
                        <th>{{ $t('text.Duration') }} :</th>
                        <td>97</td>
                    </tr>
                    <tr>
                        <th>{{ $t('text.Maximum speed') }} :</th>
                        <td>1</td>
                    </tr>
                    <tr>
                        <th>{{ $t('text.Average speed') }} :</th>
                        <td>On</td>
                    </tr>
                </table>
            </section>

        </section>
    </section>
</template>

<script>
    import TracksList_navigation from "./TracksList_navigation.vue";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "TracksList",
        components: {
            TracksList_navigation,
        },
        data() {
            return {
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                "unitsCount",
            ])
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
        },
        updated() {
            //console.log('TracksList updated')
        },
        mounted() {
        },
        beforeDestroy() {
        },
    }
</script>

<style scoped>

</style>