<template>
    <!--- Navigation --->
    <nav class="section__navigation navigation pfull__12">
        <div class="horizontal-line">
            Navigation
        </div>
    </nav>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "TracksList_navigation",
        components: {
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
            ])
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
            ...mapMutations([
            ]),
        },
        updated() {
            //console.log('TracksList_navigation updated')
        },
        mounted() {
        },
        beforeDestroy() {
        },
    }
</script>

<style scoped>

</style>